/**
 * A mixin for components inside <keep-alive>.
 */
export default {
    data() {
        return {
            /**
             * This data variable indicates if the component is currently activated.
             * Useful to conditionally render portal: <portal v-if="activated">.
             */
            activated: false,
        };
    },
    activated() {
        this.activated = true;
    },
    deactivated() {
        this.activated = false;
    },
};
