import ShowModalConfirm from '@/components/common/mixins/ShowModalConfirm';

/**
 * A mixin that will show a confirmation modal before leaving route if there's
 * some unsaved changes.
 */
export default {
    mixins: [ShowModalConfirm],
    computed: {
        hasUnsavedChanges() {
            throw new Error('`hasUnsavedChanges` must be implemented by the component.');
        },
    },
    async beforeRouteLeave(to, from, next) {
        if (this.hasUnsavedChanges) {
            const confirmed = await this.showModalConfirm(
                this.$t('common.messages.leaveUnsavedConfirmation'),
            );

            if (!confirmed) return next(false);
        }

        next();
    },
};
